import { Form, Formik } from 'formik';
import React, { useState } from 'react';

import { Box, Button, Modal, TextField, Typography } from '@mui/material';

import ConfirmDeleteDialog from 'components/ConfirmDeleteDialog';

import { Colors } from 'design/theme';

import _adminService from '../../services/admin';
import { Intros } from '../../types/intros.types';
import { validationSchemaEditIntro } from '../../types/validationSchemas';

interface EditIntroModalProps {
  open: boolean;
  handleCloseModal: () => void;
  intro: Intros;
  onUpdate: () => Promise<void>;
}

const EditIntroModal: React.FC<EditIntroModalProps> = ({
  open,
  handleCloseModal,
  intro,
  onUpdate
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const handleOpenDeleteDialog = () => setOpenConfirmDialog(true);
  const handleCloseDeleteDialog = () => setOpenConfirmDialog(false);

  const handleDelete = async () => {
    await _adminService.deleteIntro(intro.id);
    setOpenConfirmDialog(false);
    onUpdate();
    handleCloseModal();
  };

  const handleSubmit = async (values: Intros) => {
    setIsSubmitting(true);
    try {
      await _adminService.updateIntro(intro.id, values);
      onUpdate();
      handleCloseModal();
    } catch (error) {
      console.error('Error saving intro:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="intro-modal-title"
        aria-describedby="intro-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            maxHeight: '75%',
            bgcolor: 'background.paper',
            border: `2px solid ${Colors.secondary.main}`,
            borderRadius: '2rem',
            boxShadow: 24,
            p: 4
          }}>
          <Typography
            id="intro-modal-title"
            variant="h6"
            component="h2"
            paddingBottom={'1rem'}>
            Review this Intro Details
          </Typography>
          <Formik
            initialValues={intro}
            validationSchema={validationSchemaEditIntro}
            onSubmit={handleSubmit}>
            {({ errors, touched, handleChange, values }) => (
              <Form>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '10rem',
                    justifyContent: 'space-around',
                    gap: '1rem'
                  }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: Colors.primary
                    }}>
                    Title
                  </Typography>
                  <TextField
                    color="secondary"
                    fullWidth
                    name="title"
                    id="title"
                    placeholder="Title *"
                    autoComplete="title"
                    value={values.title}
                    onChange={handleChange}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                    sx={{
                      fontFamily: '"Nunito"',
                      fontWeight: '500'
                    }}
                  />

                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: Colors.primary
                    }}>
                    Intro Text
                  </Typography>
                  <Box
                    sx={{
                      maxHeight: '28rem',
                      overflowY: 'auto',
                      border: `1px solid ${Colors.lightGrey}`,
                      borderRadius: '0.25rem',
                      '&::-webkit-scrollbar': {
                        scrollbarWidth: 'none',
                        width: '8px',
                        borderRadius: '6px'
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: Colors.grey,
                        borderRadius: '6px',
                        width: '2px'
                      }
                    }}>
                    <TextField
                      color="secondary"
                      fullWidth
                      name="text"
                      id="text"
                      multiline
                      value={values.text}
                      onChange={handleChange}
                      error={touched.text && Boolean(errors.text)}
                      helperText={touched.text && errors.text}
                      InputProps={{
                        sx: {
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                          }
                        }
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: Colors.neutralGrey
                    }}>
                    Use [...] to add pauses to the narration
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginTop: '1rem'
                  }}>
                  <Button
                    sx={{ color: 'red' }}
                    onClick={handleOpenDeleteDialog}>
                    Delete
                  </Button>
                  <Box>
                    <Button
                      sx={{ marginRight: '0.5rem' }}
                      onClick={handleCloseModal}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      sx={{ color: Colors.secondary.main }}>
                      Save
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
      <ConfirmDeleteDialog
        open={openConfirmDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default EditIntroModal;
