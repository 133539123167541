import {
  AdminDetailsResponse,
  AffirmationProduct,
  UserInfoDTO
} from 'types/admin.requests';
import {
  PromotionCodeResponse,
  PromotionCodes,
  SeeedMigrations
} from 'types/apiResponse.interface';
import Roles, { AdminRoles } from 'types/roles.enum';

import { AudioProfilesAdmins } from '../../../types/admin.requests';

export interface IAdminState {
  role: Roles;
  ui: IAdminUI;
  users: Profiles;
  affirmations: AffirmationState;
  audios: AudioLab;
  affirmationsCabinet?: AffirmationProduct[] | null;
  morning: boolean;
  promotionCode: PromotionCodeState;
  seeds?: SeeedMigrations[];
}

export interface PromotionCodeState {
  promotionCode?: PromotionCodeResponse;
  allPromotionCodes?: PromotionCodes[];
}

export interface AffirmationState {
  affirmations?: GeneratedAffirmations | null;
  affirmationsReview?: AffirmationProduct[];
  affirmationsPublished?: number;
}
export interface AudioLab {
  audioProfiles?: AudioProfilesAdmins[];
  audioProfilesToReview?: AudioProfilesAdmins[];
  publishedAudioProfiles?: AudioProfilesAdmins[];
  userAudioProfiles?: Partial<AudioLibraryDefault>[];
}
export interface Profiles {
  users?: UserInfoDTO[];
  admins?: AdminDetailsResponse[];
  waitingList?: WaitingListDto[];
}
export interface AdminProfileInfo {
  name: string;
  email: string;
  subscriptionType: Roles;
  createdBy: string;
}

interface IAdminUI {
  loginError?: string;
  requestError?: string;
  successMessage?: string;
  loading: boolean;
  adminInfo?: Partial<Admin>;
}

export type Admin = {
  name: string;
  subscriptionType: AdminRoles;
  email: string;
  createdBy: string;
};
export interface GeneratedAffirmations {
  affirmationID: string;
  affirmations: Affirmation[];
}

export type Affirmation = {
  id: string;
  affirmationSentence: string;
};
export enum Voices {
  Adam = 'Adam',
  Francesca = 'Francesca',
  Ana = 'Ana',
  Jaxon = 'Jaxon',
  Christopher = 'Christopher',
  Serenity = 'Serenity',
  Brittney = 'Brittney',
  Danielle = 'Danielle',
  Dan = 'Dan',
  SoftDemure = 'Soft Demure Garden Voice',
  Allison = 'Allison'
}
export enum VoiceSpeed {
  Mellow = 0.75,
  Relaxed = 0.85,
  Moderate = 1.0,
  Lively = 1.15,
  Brisk = 1.3
}
export enum Background {
  Calming = 'Calming',
  Divine = 'Divine',
  India = 'India',
  Jump = 'Jump',
  Natures = 'Natures',
  NewAge = 'NewAge',
  Northern = 'Northern',
  Pale = 'Pale',
  Spacey = 'Spacey',
  Binaural = 'Binaural'
}
export interface AudioLibraryDefault {
  id: string;
  profileName: string;
  voice: Voices;
  voiceSpeed: VoiceSpeed;
  background: Background;
  backgroundVolume: number;
  profileDescription: string;
  audioURL: string;
}
export interface WaitingListDto {
  name: string;
  email: string;
  previousExperience: string;
  goals: string;
  value: string;
}
