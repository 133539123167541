import axios, { AxiosError } from 'axios';

import {
  AudioLibraryDefault,
  GeneratedAffirmations,
  WaitingListDto
} from 'redux/admin/types/adminState.interface';

import {
  AcceptPublishAffirmationTempalteDto,
  AcceptPublishAudioDto,
  AdminDetailsResponse,
  AffirmationAdminDTO,
  AffirmationProduct,
  AudioProfilesAdmins,
  CreateAdminAreaDto,
  DefaultAudioAdminDTO,
  EmailRequiredDto,
  PasswordRequiredDto,
  PublishAffirmationoDto,
  PublishAudioDto,
  SelectedAffirmationAdminDTO,
  UpdateAffirmationDto,
  UpdateAudioDto,
  UserInfoDTO
} from 'types/admin.requests';
import {
  CheckMorningAffirmationDTO,
  IApiResponse,
  PromotionCodeData,
  PromotionCodeResponse,
  PromotionCodes,
  SeedData,
  SeeedMigrations
} from 'types/apiResponse.interface';
import loginRequestType from 'types/loginRequest.type';

import { setLocalStorage } from 'utils/localstorage';

import { GeneratedIntroText, IntroForm, Intros } from '../types/intros.types';
import { api, baseURL } from './instance';

export interface ErrorResponse {
  message: string;
  [key: string]: any;
}
const isAxiosError = (error: any): error is AxiosError => {
  return error.isAxiosError === true;
};

const _adminService = {
  createAdmin: async (data: CreateAdminAreaDto) => {
    const response = await api.post<IApiResponse<string>>('/admin-area', data);
    return response.data;
  },

  login: async (data: loginRequestType) => {
    try {
      const response = await axios.post(`${baseURL}/admin-area/login`, data);
      setLocalStorage('token', response.data.data.token);
      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const responseData = error.response.data as ErrorResponse;
        const errorMessage = responseData.message || 'Login failed';
        throw new Error(errorMessage);
      }
      throw error;
    }
  },

  getAdminDetails: async () => {
    const response = await api.get<IApiResponse<AdminDetailsResponse>>(
      'admin-area/admin-info'
    );
    return response.data;
  },

  createAffirmations: async (data: AffirmationAdminDTO) => {
    const response = await api.post<IApiResponse<GeneratedAffirmations>>(
      'admin-area/generate-admin-affirmations',
      data
    );
    return response.data;
  },

  deleteAdmin: async (adminId: string) => {
    const response = await api.delete(`/admin-area/${adminId}`);
    return response.data;
  },

  getAdmins: async () => {
    const response = await api.get<IApiResponse<AdminDetailsResponse[]>>(
      'admin-area/admins'
    );
    return response.data;
  },

  getAdminUsers: async () => {
    const response = await api.get<IApiResponse<UserInfoDTO[]>>(
      'admin-area/users-admin'
    );
    return response.data;
  },

  createSelectedAffirmations: async (data: SelectedAffirmationAdminDTO) => {
    const response = await api.post<IApiResponse<{ id: string }>>(
      'admin-area/selected-admin-affirmations',
      data
    );
    return response.data;
  },

  getCreatedAffirmations: async () => {
    const response = await api.get<IApiResponse<AffirmationProduct[]>>(
      'admin-area/created-affirmations'
    );
    return response.data;
  },

  requestPassword: async (data: EmailRequiredDto) => {
    const response = await axios.post<IApiResponse<EmailRequiredDto>>(
      `${baseURL}/admin-area/request-password`,
      data
    );
    return response.data;
  },

  restorePassword: async (data: PasswordRequiredDto) => {
    return await axios.post<IApiResponse<boolean>>(
      `${baseURL}/admin-area/restore-password`,
      data,
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  },

  getAudioProfiles: async () => {
    const response = await api.get<IApiResponse<AudioProfilesAdmins[]>>(
      'admin-area/audio-profiles'
    );
    return response.data;
  },

  publishAudioProfile: async (data: PublishAudioDto) => {
    const response = await api.post<IApiResponse<boolean>>(
      'admin-area/publish-audio-profile',
      data
    );
    return response.data;
  },

  audioProfilesReviewed: async (data: AcceptPublishAudioDto) => {
    const response = await api.post<IApiResponse<boolean>>(
      'admin-area/audio-profile-reviewer',
      data
    );
    return response.data;
  },

  getAudioProfilesToReview: async () => {
    const response = await api.get<IApiResponse<AudioProfilesAdmins[]>>(
      'admin-area/audio-profile-reviewer'
    );
    return response.data;
  },

  getPublishedAudioProfiles: async () => {
    const response = await api.get<IApiResponse<AudioProfilesAdmins[]>>(
      'admin-area/published-audio-profiles'
    );
    return response.data;
  },

  createAudioProfile: async (data: DefaultAudioAdminDTO) => {
    const response = await api.post<IApiResponse<string>>(
      'admin-area/create-audio-profile',
      data
    );
    return response.data;
  },

  affirmationsTemplateToReview: async () => {
    const response = await api.get<IApiResponse<AffirmationProduct[]>>(
      '/admin-area/affirmation-templates-reviewer'
    );
    return response.data;
  },

  affirmationsReviewed: async (data: AcceptPublishAffirmationTempalteDto) => {
    const response = await api.post<IApiResponse<boolean>>(
      '/admin-area/affirmation-template-reviewer',
      data
    );
    return response.data.data;
  },

  publishAffirmationTemplate: async (data: PublishAffirmationoDto) => {
    const response = await api.post<IApiResponse<boolean>>(
      '/admin-area/publish-affirmations-template',
      data
    );
    return response.data;
  },

  updateAudioDetails: async (data: UpdateAudioDto) => {
    const response = await api.patch<IApiResponse<AudioProfilesAdmins>>(
      'admin-area/audio-details-update',
      data
    );
    return response.data;
  },

  deleteAdminAudio: async (audioID: string) => {
    const response = await api.delete<IApiResponse<boolean>>(
      `admin-area/delete-admin-audio/${audioID}`
    );
    return response.data;
  },

  updateAffirmationDetails: async (data: UpdateAffirmationDto) => {
    const response = await api.patch<IApiResponse<AudioProfilesAdmins>>(
      'admin-area/affirmation-details-update',
      data
    );
    return response.data;
  },

  deleteAdminAffirmation: async (affirmationID: string) => {
    const response = await api.delete<IApiResponse<boolean>>(
      `admin-area/delete-admin-affirmation/${affirmationID}`
    );
    return response.data;
  },
  checkMorningAffirmations: async (data?: CheckMorningAffirmationDTO) => {
    const response = await api.post<IApiResponse<boolean>>(
      'admin-area/check-affirmations-morning',
      data
    );
    return response.data;
  },
  getDefaultAudioProfiles: () => {
    return api.get<IApiResponse<AudioLibraryDefault[]>>(
      `${baseURL}/audio-profiles/audio-profile-onboarding`
    );
  },
  getWaitingList: () => {
    return api.get<IApiResponse<WaitingListDto[]>>(
      `${baseURL}/admin-area/waiting-list-users`
    );
  },
  checkAffirmationsLength: () => {
    return api.get<IApiResponse<number>>(
      `${baseURL}/admin-area/check-published-count`
    );
  },
  generatePromotionCode: async (data: PromotionCodeData) => {
    const response = await api.post<IApiResponse<PromotionCodeResponse>>(
      `${baseURL}/admin-area/promotion-code`,
      data
    );
    return response.data;
  },
  getPromotionCodes: async () => {
    const response = await api.get<IApiResponse<PromotionCodes[]>>(
      `${baseURL}/admin-area/promotion-codes`
    );
    return response.data;
  },
  uploadSeedAffirmation: async (params: SeedData) => {
    const formData = new FormData();
    formData.append('file', params.file);
    formData.append('seedType', params.seedType);
    const response = await api.post(
      `${baseURL}/admin-area/upload-seed`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.data;
  },
  getAllSeeds: async () => {
    const response = await api.get<IApiResponse<SeeedMigrations[]>>(
      `${baseURL}/admin-area/get-seeds`
    );
    return response.data.data;
  },
  generateIntroText: async (payload: GeneratedIntroText) => {
    const response = await api.post<IApiResponse<{ introText: string }>>(
      `${baseURL}/admin-area/generate-intro-text`,
      payload
    );
    return response.data.data;
  },
  createIntro: async (payload: IntroForm) => {
    const response = await api.post<IApiResponse<Intros>>(
      `${baseURL}/admin-area/create-intro`,
      payload
    );
    return response.data.data;
  },
  getIntros: async (filter?: { published?: boolean; reviewed?: boolean }) => {
    const response = await api.get<IApiResponse<Intros[]>>(
      `${baseURL}/admin-area/intros`,
      {
        params: filter
      }
    );
    return response.data.data;
  },
  updateIntro: async (id: string, payload: Partial<Intros>) => {
    const response = await api.patch<IApiResponse<Intros>>(
      `${baseURL}/admin-area/intros/${id}`,
      payload
    );
    return response.data.data;
  },
  publishIntro: async (id: string) => {
    const response = await api.patch<IApiResponse<Intros>>(
      `${baseURL}/admin-area/intros/publish/${id}`
    );
    return response.data.data;
  },
  deleteIntro: async (id: string) => {
    const response = await api.delete<IApiResponse<{ message: string }>>(
      `${baseURL}/admin-area/intros/${id}`
    );
    return response.data.data;
  }
};

export default _adminService;
