import { Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';

import { Box, Button, Grid, TextField, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { validationSchemaIntro } from 'types/validationSchemas';

import _adminService from '../../services/admin';
import { CircularLogoLoader } from '../CircularLogoLoader';

interface FormValues {
  title: string;
  message: string;
  duration: string;
  introText: string;
}

const IntrosLab: React.FC = () => {
  const [step, setStep] = useState(1);
  const [localError, setLocalError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const initialFormData: FormValues = {
    title: '',
    message: '',
    duration: '',
    introText: 'Set an intro text'
  };

  const handleFirstStepSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);
      const { introText } = await _adminService.generateIntroText(values);

      return introText;
    } catch (error) {
      setLocalError('Failed to generate intro. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSecondStepSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);
      const result = await _adminService.createIntro(values);

      if (result) setIsSuccess(true);

      setLocalError('');
    } catch (error) {
      setLocalError('Failed to submit the intro. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isSuccess) {
    return (
      <Box
        sx={{
          margin: 'auto',
          background: 'rgba(53, 101, 105, 0.3)',
          height: '8rem',
          display: 'flex',
          alignItems: 'center',
          borderRadius: '0.25rem',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
        <Typography
          fontFamily='"Nunito"'
          fontSize={'1.25rem'}
          color={Colors.bgGreen}
          sx={{
            width: '70%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0.25rem',
            textAlign: 'center',
            gap: '1rem'
          }}>
          Intro submitted successfully!
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ paddingLeft: '1rem', margin: 0, width: '100%' }}>
      <Typography
        sx={{
          fontWeight: 500,
          color: Colors.bgGreen,
          fontFamily: '"Nunito"',
          fontSize: '1.25em',
          marginTop: '0.5rem',
          marginBottom: '1rem'
        }}>
        Intros Lab
      </Typography>
      <Formik<FormValues>
        initialValues={initialFormData}
        validationSchema={validationSchemaIntro}
        onSubmit={async (values, { setValues }) => {
          if (step === 1) {
            const responese = await handleFirstStepSubmit(values);
            console.log(responese);
            if (responese) {
              setValues({ ...values, introText: responese });
              setStep(2);
            }
          } else {
            handleSecondStepSubmit(values);
          }
        }}>
        {(formik: FormikProps<FormValues>) => (
          <Form>
            {step === 1 && (
              <Grid
                container
                spacing={2}
                sx={{ minHeight: '100%', overflow: 'hidden' }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled={isLoading}
                    id="title"
                    name="title"
                    label="Title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.title && formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    inputProps={{ maxLength: 80 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled={isLoading}
                    id="message"
                    name="message"
                    label="Message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.message && formik.errors.message
                    )}
                    helperText={formik.touched.message && formik.errors.message}
                    multiline
                    minRows={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled={isLoading}
                    id="duration"
                    name="duration"
                    label="Duration (minutes)"
                    type="number"
                    value={formik.values.duration}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.duration && formik.errors.duration
                    )}
                    helperText={
                      formik.touched.duration && formik.errors.duration
                    }
                  />
                </Grid>
              </Grid>
            )}
            {step === 2 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled={isLoading}
                    id="title"
                    name="title"
                    label="Title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.title && formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    inputProps={{ maxLength: 80 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled={isLoading}
                    id="introText"
                    name="introText"
                    label="Generated Intro"
                    value={formik.values.introText}
                    onChange={formik.handleChange}
                    multiline
                    minRows={4}
                    error={Boolean(
                      formik.touched.introText && formik.errors.introText
                    )}
                    helperText={
                      formik.touched.introText && formik.errors.introText
                    }
                  />
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: Colors.neutralGrey
                    }}>
                    Use [...] to add pauses to the narration
                  </Typography>
                </Grid>
              </Grid>
            )}
            {localError && <Typography color="error">{localError}</Typography>}

            {isLoading ? (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  marginTop: '2rem'
                }}>
                <CircularLogoLoader />
              </Box>
            ) : (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignContent: 'center'
                }}>
                <Button type="submit" variant="contained" sx={{ mt: 2, mb: 1 }}>
                  {step === 1 ? 'Generate Intro Text' : 'Submit Intro'}
                </Button>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default IntrosLab;
