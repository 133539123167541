import React, { useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import _adminService from '../../services/admin';
import { Intros } from '../../types/intros.types';
import EditIntroModal from './EditIntroModal';
import IntroTextModal from './IntroTextModal';

interface IntroCardProps {
  intro: Intros;
  onUpdate: () => Promise<void>;
}

const IntroCard: React.FC<IntroCardProps> = ({ intro, onUpdate }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isIntroTextModalOpen, setIsIntroTextModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAccept = async () => {
    try {
      setIsLoading(true);
      await _adminService.updateIntro(intro.id, { reviewed: true });
      await onUpdate();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePublish = async () => {
    try {
      setIsLoading(true);
      await _adminService.publishIntro(intro.id);
      await onUpdate();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        background: Colors.white,
        borderRadius: '0.25rem',
        display: 'flex',
        flexDirection: 'row',
        color: Colors.green.main,
        border: `1px solid ${Colors.lightGrey}`,
        borderBottom: `0.5rem solid ${Colors.green.light}`
      }}>
      <CardContent sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            paddingY: '1rem',
            paddingX: '0.5rem'
          }}>
          <Typography
            fontFamily={'"Nunito"'}
            fontSize={'1rem'}
            fontWeight={400}>
            Title:
            <span style={{ fontWeight: 700 }}> {intro.title}</span>
          </Typography>{' '}
          <Typography
            fontFamily={'"Nunito"'}
            fontSize={'1rem'}
            fontWeight={400}>
            Duration:
            <span style={{ fontWeight: 700 }}> {intro.duration} min</span>
          </Typography>{' '}
          <Button
            sx={{ marginY: '0.5rem' }}
            variant="outlined"
            onClick={() => setIsIntroTextModalOpen(true)}>
            Read Intro Text
          </Button>
          <Typography
            fontFamily='"Nunito"'
            fontSize="1rem"
            fontWeight={400}
            sx={{ marginTop: '0.15rem' }}>
            Published:{' '}
            <span style={{ fontWeight: 700 }}>
              {intro.published ? 'True' : 'False'}
            </span>
          </Typography>
          <Typography
            fontFamily='"Nunito"'
            fontSize="1rem"
            fontWeight={400}
            sx={{ marginTop: '0.15rem' }}>
            In Review:{' '}
            <span style={{ fontWeight: 700 }}>
              {intro.reviewed ? 'True' : 'False'}
            </span>
          </Typography>
          {!intro.reviewed && (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-around',
                alignContent: 'center',
                marginTop: '1rem'
              }}>
              <Button
                disabled={intro.published && !intro.reviewed}
                variant="contained"
                onClick={() => {
                  setIsEditModalOpen(true);
                }}
                sx={{ minWidth: '5rem' }}>
                Edit
              </Button>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-around',
              alignContent: 'center',
              marginTop: '1rem',
              marginBottom: '2rem'
            }}>
            {!intro.reviewed && (
              <Button
                variant="contained"
                onClick={handleAccept}
                sx={{ minWidth: '5rem' }}>
                Accept
              </Button>
            )}
            {intro.reviewed && (
              <Button
                disabled={intro.published || isLoading}
                variant="contained"
                onClick={handlePublish}
                sx={{ minWidth: '5rem' }}>
                {isLoading ? (
                  <>
                    <CircularProgress
                      size={20}
                      color="inherit"
                      sx={{ marginRight: '0.5rem' }}
                    />
                    Publishing...
                  </>
                ) : intro.published ? (
                  'Published'
                ) : (
                  'Publish'
                )}
              </Button>
            )}
          </Box>
        </Box>
      </CardContent>

      <EditIntroModal
        intro={intro}
        open={isEditModalOpen}
        handleCloseModal={() => setIsEditModalOpen(false)}
        onUpdate={onUpdate}
      />

      <IntroTextModal
        intro={intro}
        open={isIntroTextModalOpen}
        handleCloseModal={() => setIsIntroTextModalOpen(false)}
      />
    </Card>
  );
};

export default IntroCard;
