import React from 'react';

import { Box, Button, Modal, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { Intros } from '../../types/intros.types';

interface EditIntroModalProps {
  open: boolean;
  handleCloseModal: () => void;
  intro: Intros;
}

const IntroTextModal: React.FC<EditIntroModalProps> = ({
  open,
  handleCloseModal,
  intro
}) => {
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="intro-modal-title"
      aria-describedby="intro-modal-description">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          maxHeight: '75%',
          bgcolor: 'background.paper',
          border: `2px solid ${Colors.secondary.main}`,
          borderRadius: '2rem',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Typography
          id="intro-modal-title"
          variant="h6"
          component="h2"
          paddingBottom={'1rem'}>
          Intro Text Details
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '10rem',
            justifyContent: 'flex-start',
            gap: '1rem',
            flexGrow: 1
          }}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '1.25rem',
              color: Colors.primary
            }}>
            {intro.title}
          </Typography>

          <Box
            sx={{
              marginY: '0.5rem',
              width: '100%',
              paddingY: '0.5rem',
              paddingX: '0.25rem',
              overflowY: 'auto',
              whiteSpace: 'normal',
              '&::-webkit-scrollbar': {
                scrollbarWidth: 'none',
                width: '8px',
                borderRadius: '6px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: Colors.grey,
                borderRadius: '6px',
                width: '2px'
              },
              border: `1px solid ${Colors.lightGrey}`,
              borderRadius: '0.25rem'
            }}>
            <Typography
              fontFamily={'"Nunito"'}
              fontSize={'1rem'}
              fontWeight={400}
              sx={{
                marginTop: '0.15rem',
                paddingBottom: '0.5rem',
                color: Colors.neutralGrey,
                whiteSpace: 'pre-line'
              }}>
              {intro.text}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}>
          <Box>
            <Button sx={{ marginRight: '0.5rem' }} onClick={handleCloseModal}>
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default IntroTextModal;
