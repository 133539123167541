import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import _adminService from '../../services/admin';
import { Intros } from '../../types/intros.types';
import IntroCard from './IntroCard';

export const Cabinet: React.FC = () => {
  const [intros, setIntros] = useState<Intros[]>([]);

  const fetchIntros = async () => {
    try {
      const fetchedIntros = await _adminService.getIntros({ reviewed: true });
      setIntros(fetchedIntros);
    } catch (error) {
      console.error('Error fetching intros:', error);
    }
  };

  useEffect(() => {
    fetchIntros();
  }, []);

  if (intros.length === 0) {
    return (
      <Box
        sx={{
          margin: 'auto',
          background: 'rgba(53, 101, 105, 0.3)',
          height: '8rem',
          display: 'flex',
          alignItems: 'center',
          borderRadius: '0.25rem',
          justifyContent: 'center',
          flexDirection: 'column'
        }}>
        <Typography
          fontFamily='"Nunito"'
          fontSize={'1.25rem'}
          color={Colors.bgGreen}
          sx={{
            width: '70%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0.25rem',
            textAlign: 'center',
            gap: '1rem'
          }}>
          No Intros to review!
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ paddingLeft: '1rem', margin: 0, width: '100%' }}>
      <Typography
        sx={{
          fontWeight: 500,
          color: Colors.bgGreen,
          fontFamily: '"Nunito"',
          fontSize: '1.25em',
          marginTop: '0.5rem'
        }}>
        Intros on review
      </Typography>
      <Grid container spacing={2} mt={0}>
        {intros.map((intro, index) => (
          <Grid item key={index} xs={12} md={4}>
            <IntroCard key={index} intro={intro} onUpdate={fetchIntros} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Cabinet;
