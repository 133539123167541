import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useState } from 'react';

import { Box, Button, Container, Grid } from '@mui/material';

import { Colors } from 'design/theme';

import { getAudioProfiles } from 'redux/admin/admin.action';
import { adminSelector } from 'redux/admin/admin.selector';

import { AdminRoles } from 'types/roles.enum';

import { getAudioProfilesToReview } from '../../redux/admin/admin.action';
import Cabinet from './Cabinet';
import IntrosLab from './IntrosLab';
import Review from './Review';

enum TabOptions {
  INTROS_LAB = 'Intro Lab',
  INTROS_CABINET = 'Intros Cabinet',
  REVIEW = 'Review'
}

export const IntrosHub: React.FC = () => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<TabOptions>(TabOptions.INTROS_LAB);
  const adminInfo = useAppSelector(adminSelector).ui.adminInfo;

  const handleTabClick = (tab: TabOptions) => {
    setActiveTab(tab);
    if (tab === TabOptions.INTROS_CABINET) {
      dispatch(getAudioProfiles());
    }
    if (tab === TabOptions.REVIEW) {
      dispatch(getAudioProfilesToReview());
    }
  };

  return (
    <Container
      sx={{
        margin: 'auto',
        justifyContent: 'center',
        pb: '2rem'
      }}>
      <Grid
        container
        spacing={1}
        sx={{ borderBottom: `1px solid ${Colors.grey}` }}>
        <Grid item>
          <Button
            sx={{
              fontFamily: '"Nunito"',
              fontSize: '1.15rem',
              borderBottom:
                activeTab === TabOptions.INTROS_LAB
                  ? `2px solid ${Colors.black}`
                  : '2px solid transparent',
              borderRadius: '0',
              color:
                activeTab === TabOptions.INTROS_LAB
                  ? Colors.black
                  : Colors.neutral,
              fontWeight: '500'
            }}
            onClick={() => handleTabClick(TabOptions.INTROS_LAB)}>
            {TabOptions.INTROS_LAB}
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{
              fontFamily: '"Nunito"',
              fontSize: '1.15rem',
              borderBottom:
                activeTab === TabOptions.INTROS_CABINET
                  ? `2px solid ${Colors.black}`
                  : '2px solid transparent',
              borderRadius: '0',
              color:
                activeTab === TabOptions.INTROS_CABINET
                  ? Colors.black
                  : Colors.neutral,
              fontWeight: '500'
            }}
            onClick={() => handleTabClick(TabOptions.INTROS_CABINET)}>
            {TabOptions.INTROS_CABINET}
          </Button>
        </Grid>
        {adminInfo?.subscriptionType === AdminRoles.SUPERADMIN && (
          <Grid item>
            <Button
              sx={{
                fontFamily: '"Nunito"',
                fontSize: '1.15rem',
                borderBottom:
                  activeTab === TabOptions.REVIEW
                    ? `2px solid ${Colors.black}`
                    : '2px solid transparent',
                borderRadius: '0',
                color:
                  activeTab === TabOptions.REVIEW
                    ? Colors.black
                    : Colors.neutral,
                fontWeight: '500'
              }}
              onClick={() => handleTabClick(TabOptions.REVIEW)}>
              {TabOptions.REVIEW}
            </Button>
          </Grid>
        )}
      </Grid>
      <Box sx={{ marginTop: '1.5rem' }}>
        {activeTab === TabOptions.INTROS_LAB && <IntrosLab />}
        {activeTab === TabOptions.INTROS_CABINET && <Cabinet />}
        {activeTab === TabOptions.REVIEW && <Review />}
      </Box>
    </Container>
  );
};

export default IntrosHub;
