import { useAppDispatch } from 'hooks/useAppDispatch';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Button, Container, Grid, Typography } from '@mui/material';

import AdminPanel from 'components/Admins/AdminPanel';
import AffiliatesPanel from 'components/Affiliates/AffiliatesPanel';
import AffirmationsLab from 'components/Affirmations/AffirmationsLab';
import { SeedTab } from 'components/Affirmations/SeedTab';
import AudioLab from 'components/Audios/AudioLab';
import UsersDashboard from 'components/Users/UsersDashboard';

import { Colors } from 'design/theme';

import {
  getAdminUsers,
  getAdmins,
  getAllSeeds
} from 'redux/admin/admin.action';
import { getAllAffiliationsProcess } from 'redux/affiliations/affiliations.action';

import { TabsEnum } from 'types/tabs.enum';

import IntrosHub from '../components/Intros/IntrosHub';

export const AdminHub: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();
  const queryParams = new URLSearchParams(location.search);
  const defaultTab = queryParams.get('defaultTab') as TabsEnum;

  const [activeTab, setActiveTab] = useState<TabsEnum>(
    defaultTab || TabsEnum.Users
  );

  const handleTabClick = async (tabName: TabsEnum) => {
    setActiveTab(tabName);

    if (tabName === TabsEnum.Users) {
      dispatch(getAdminUsers());
    } else if (tabName === TabsEnum.Admins) {
      dispatch(getAdmins());
    } else if (tabName === TabsEnum.Affiliations) {
      dispatch(getAllAffiliationsProcess());
    } else if (tabName === TabsEnum.Seeds) {
      dispatch(getAllSeeds());
    }
  };

  const tabContent = (
    <Box
      sx={{
        margin: isLargeScreen ? '1rem' : '1rem 0 0 0',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center'
      }}>
      {activeTab === TabsEnum.Affirmations && <AffirmationsLab />}
      {activeTab === TabsEnum.Audio && <AudioLab />}
      {activeTab === TabsEnum.Intros && <IntrosHub />}
      {activeTab === TabsEnum.Users && <UsersDashboard />}
      {activeTab === TabsEnum.Admins && <AdminPanel />}
      {activeTab === TabsEnum.Affiliations && <AffiliatesPanel />}
      {activeTab === TabsEnum.Seeds && <SeedTab />}
    </Box>
  );

  return (
    <Container
      sx={{
        marginTop: '3rem',
        display: 'flex',
        pb: '2rem',
        minHeight: 'calc(100vh - 8rem)',
        padding: isLargeScreen ? undefined : 0
      }}>
      {isLargeScreen ? (
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box
            sx={{
              flexDirection: 'column',
              borderRight: `1px solid ${Colors.grey}`,
              minWidth: '12rem'
            }}>
            <Typography
              variant="h5"
              fontWeight={500}
              color={Colors.bgGreen}
              mb={3}>
              Admin Lab
            </Typography>
            <Button
              sx={{
                fontSize: '1rem',
                borderBottom:
                  activeTab === TabsEnum.Users
                    ? `2px solid ${Colors.secondary.main}`
                    : undefined,
                borderRadius: 0,
                color:
                  activeTab === TabsEnum.Users
                    ? Colors.secondary.main
                    : Colors.neutral,
                fontWeight: '500',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start'
              }}
              onClick={() => handleTabClick(TabsEnum.Users)}>
              Users
            </Button>
            <Button
              sx={{
                fontSize: '1rem',
                borderBottom:
                  activeTab === TabsEnum.Audio
                    ? `2px solid ${Colors.secondary.main}`
                    : undefined,
                borderRadius: 0,
                color:
                  activeTab === TabsEnum.Audio
                    ? Colors.secondary.main
                    : Colors.neutral,
                fontWeight: '500',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start'
              }}
              onClick={() => handleTabClick(TabsEnum.Audio)}>
              Audio
            </Button>

            <Button
              sx={{
                fontSize: '1rem',
                borderBottom:
                  activeTab === TabsEnum.Intros
                    ? `2px solid ${Colors.secondary.main}`
                    : undefined,
                borderRadius: 0,
                color:
                  activeTab === TabsEnum.Intros
                    ? Colors.secondary.main
                    : Colors.neutral,
                fontWeight: '500',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start'
              }}
              onClick={() => handleTabClick(TabsEnum.Intros)}>
              Intros
            </Button>

            <Button
              sx={{
                fontSize: '1rem',
                borderBottom:
                  activeTab === TabsEnum.Admins
                    ? `2px solid ${Colors.secondary.main}`
                    : undefined,
                borderRadius: 0,
                color:
                  activeTab === TabsEnum.Admins
                    ? Colors.secondary.main
                    : Colors.neutral,
                fontWeight: '500',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start'
              }}
              onClick={() => handleTabClick(TabsEnum.Admins)}>
              Admins
            </Button>
            <Button
              sx={{
                fontSize: '1rem',
                borderBottom:
                  activeTab === TabsEnum.Affirmations
                    ? `2px solid ${Colors.secondary.main}`
                    : undefined,
                borderRadius: 0,
                color:
                  activeTab === TabsEnum.Affirmations
                    ? Colors.secondary.main
                    : Colors.neutral,
                fontWeight: '500',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start'
              }}
              onClick={() => handleTabClick(TabsEnum.Affirmations)}>
              Affirmations
            </Button>

            <Button
              sx={{
                fontSize: '1rem',
                borderBottom:
                  activeTab === TabsEnum.Affiliations
                    ? `2px solid ${Colors.secondary.main}`
                    : undefined,
                borderRadius: 0,
                color:
                  activeTab === TabsEnum.Affiliations
                    ? Colors.secondary.main
                    : Colors.neutral,
                fontWeight: '500',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start'
              }}
              onClick={() => handleTabClick(TabsEnum.Affiliations)}>
              Affiliates
            </Button>

            <Button
              sx={{
                fontSize: '1rem',
                borderBottom:
                  activeTab === TabsEnum.Seeds
                    ? `2px solid ${Colors.secondary.main}`
                    : undefined,
                borderRadius: 0,
                color:
                  activeTab === TabsEnum.Seeds
                    ? Colors.secondary.main
                    : Colors.neutral,
                fontWeight: '500',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start'
              }}
              onClick={() => handleTabClick(TabsEnum.Seeds)}>
              Seeds
            </Button>
          </Box>
          <Box sx={{ flex: 1, overflow: 'auto' }}>{tabContent}</Box>
        </Box>
      ) : (
        <Grid
          container
          direction="column"
          sx={{ width: '100%', margin: '0 0.5rem' }}>
          <Grid item sx={{ p: 2 }}>
            <Typography variant="h5" fontWeight={500} color={Colors.bgGreen}>
              Admin Lab
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              borderBottom: `1px solid ${Colors.grey}`
            }}>
            <Button
              sx={{
                fontSize: '1rem',
                borderBottom:
                  activeTab === TabsEnum.Audio
                    ? `2px solid ${Colors.secondary.main}`
                    : undefined,
                borderRadius: 0,
                color:
                  activeTab === TabsEnum.Audio
                    ? Colors.secondary.main
                    : Colors.neutral,
                fontWeight: '500',
                display: 'flex',
                justifyContent: 'flex-start'
              }}
              onClick={() => handleTabClick(TabsEnum.Audio)}>
              Audio
            </Button>
            <Button
              sx={{
                fontSize: '1rem',
                borderBottom:
                  activeTab === TabsEnum.Affirmations
                    ? `2px solid ${Colors.secondary.main}`
                    : undefined,
                borderRadius: 0,
                color:
                  activeTab === TabsEnum.Affirmations
                    ? Colors.secondary.main
                    : Colors.neutral,
                fontWeight: '500',
                display: 'flex',
                justifyContent: 'flex-start'
              }}
              onClick={() => handleTabClick(TabsEnum.Affirmations)}>
              Affirmations
            </Button>
            <Button
              sx={{
                fontSize: '1rem',
                borderBottom:
                  activeTab === TabsEnum.Users
                    ? `2px solid ${Colors.secondary.main}`
                    : undefined,
                borderRadius: 0,
                color:
                  activeTab === TabsEnum.Users
                    ? Colors.secondary.main
                    : Colors.neutral,
                fontWeight: '500',
                display: 'flex',
                justifyContent: 'flex-start'
              }}
              onClick={() => handleTabClick(TabsEnum.Users)}>
              Users
            </Button>
            <Button
              sx={{
                fontSize: '1rem',
                borderBottom:
                  activeTab === TabsEnum.Admins
                    ? `2px solid ${Colors.secondary.main}`
                    : undefined,
                borderRadius: 0,
                color:
                  activeTab === TabsEnum.Admins
                    ? Colors.secondary.main
                    : Colors.neutral,
                fontWeight: '500'
              }}
              onClick={() => handleTabClick(TabsEnum.Admins)}>
              Admins
            </Button>
          </Grid>
          <Grid item sx={{ flex: 1, overflow: 'auto' }}>
            {tabContent}
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default AdminHub;
